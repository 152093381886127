<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="mb-7">
          <v-card-text class="pa-5">
            <div class="d-sm-flex align-center">
              <div>
                <h2 class="font-weight-regular title">Información de inscripción</h2>
                <h6 class="body-2 mb-1 subtitle-2 font-weight-light">Datos de inscritos</h6>
              </div>
            </div>
          </v-card-text>
          <v-card-text class="pa-5 border-bottom">
            <TheInscriptionForm
                v-if="inscription"
                :inscription="inscription"
                :initial="initial"
                @validated="doUpdateInscription($event)"
                :updateInscEdited="updateInscEdited"
                :saveLoading="saveLoading"
                @deleted="deleted=true"
            ></TheInscriptionForm>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import updateForm from '@/mixins/updateForm'
import TheInscriptionForm from "@/components/dashboardComponents/TheInscriptionForm"

export default {
  name: "Formulario",
  components: {
    TheInscriptionForm
  },
  mixins: [updateForm],
  data: () => ({
    page: {
      title: "Formulario"
    },
    updateInscEdited: false,
    initial: null,
    edit: true,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard/index/"
      },
      {
        text: "Formularios",
        disabled: false,
        href: "/dashboard/inscriptions/"
      }
    ],
    inscription: null,
    saveLoading: false,
    deleted: false
  }),
  methods: {
    async fetchInscription() {
      const inscriptionId = this.$route.params.id
      const inscriptionResponse = await this.$http.get(`api/admin/matriculas/formularios/${inscriptionId}/`)
      this.inscription = {...inscriptionResponse.data}
      this.initial = {
        ...this.inscription,
        vouchers: [...this.inscription.vouchers]
      }
    },
    async updateInscription(inscription) {
      // console.log(inscription)
      const payload = new FormData()
      payload.append('region', inscription.region)
      payload.append('nombres', inscription.nombres)
      payload.append('apellidos', inscription.apellidos)
      payload.append('documento', inscription.documento)
      payload.append('email', inscription.email)
      payload.append('comentario', inscription.comentario || '')
      payload.append('carnet_cmp', inscription.carnet_cmp || '')
      // payload.append('autoriza_foto', inscription.autoriza_foto)
      payload.append('fecha_nacimiento', inscription.fecha_nacimiento)
      payload.append('region', inscription.region)
      payload.append('celular', inscription.celular)
      payload.append('seccion_id', inscription.seccion.id)
      payload.append('estado', inscription.estado)
      payload.append('institucion_id', inscription.institucion.id)
      payload.append('otro_canal_referencia', inscription.otro_canal_referencia || '')
      if (typeof inscription.foto_dni !== 'string') {
        payload.append('foto_dni', inscription.foto_dni)
      }
      inscription.grados_formacion.forEach((grado) => {
        payload.append('grados_formacion_ids', grado.id)
      })
      inscription.canales_referencia.forEach((canalReferencia) => {
        payload.append('canales_referencia_ids', canalReferencia.id)
      })
      inscription.deletedVouchers.forEach((deletedVoucher) => {
        payload.append('vouchers_eliminados', deletedVoucher.id)
      })
      inscription.newFiles.forEach((newFile, i) => {
        payload.append(`vouchers_nuevos[${i}]imagen`, newFile.image)
      })
      const inscriptionResponse = await this.$http.patch(`api/admin/matriculas/formularios/${inscription.id}/`, payload)
      this.inscription = inscriptionResponse.data
      // this.$set(this, inscription, inscriptionResponse.data)
    },
    async doUpdateInscription(event) {
      try {
        this.saveLoading = true
        await this.updateInscription(event)
        await this.fetchInscription()
        this.updateInscEdited = !this.updateInscEdited // to test if some field has changed respect to the last inscription data
        this.saveLoading = false
        await this.$root.$confirm({
          title: 'Mensaje',
          message: 'Inscripción actualizada.'
        })
        // await this.$router.push({name: 'Inscriptions'})
      } catch (e) {
        this.saveLoading = false
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo de nuevo en unos minutos.'
        })
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    const changesNotUpdated = this.testChanges()
    if (this.deleted || changesNotUpdated === 20) {
      next()
    } else {
      this.firstSaveInfo()
    }
  },
  async mounted() {
    await this.fetchInscription()
    const newBreadCrumb = {
      text: `${this.inscription.nombres} ${this.inscription.apellidos}`,
      disabled: true
    }
    this.breadcrumbs.push(newBreadCrumb)
  }
}
</script>

<style lang="scss">
.close-button:hover {
  opacity: 0.8%;
}

.upload-button {
  cursor: pointer;
  height: 50px;
  width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 10px;
}

.voucher-image {
  width: 100%;
}

.card-vouchers {
  border: 1px solid gray !important;
}
</style>
